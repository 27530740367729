import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useOutsideClickDetector } from '../../../utils/useOutsideClickDetector';
import './overlay.scss';

export const Overlay = props => {
    let spanRef = useRef(null);
    useOutsideClickDetector(spanRef, props.outsideClick);

    return (
        <div className={`overlay ${props.type} ${(props.promptPresent && process.env.REACT_APP_BASE_DOMAIN == "hirist") ? "overlay-prompt": ""}`}>
            <span ref={spanRef}>{props.children}</span>
        </div>
    );
};

Overlay.propTypes = {
    outsideClick: PropTypes.func.isRequired,
};
