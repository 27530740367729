import { endpoints } from '../../../constants/endpoints';
import { checkFeature } from '../../../utils/checkFeature';

const navItems = [
    {
        title: 'Dashboard',
        titleClassToAddMenu: "profile-more-menu",
        link: endpoints.dashboard,
        onClick: handler => handler.bind(null, 'dashboard'),
        isHidden: !checkFeature('dashboard'),
        children: [],
    },
    {
        title: 'Jobs',
        titleClassToAddMenu: "profile-more-menu",
        link: endpoints.myJobs,
        onClick: handler => handler.bind(null, 'myjobs'),
        isReact: true,
        isHidden: !checkFeature('myJobs'),
        children: [
            {
                title: 'My Jobs',
                link: endpoints.myJobs,
                onClick: handler => handler.bind(null, 'myjobs'),
            },
            {
                title: 'Post Job',
                link: endpoints.postJob,
                onClick: handler => handler.bind(null, 'postjob'),
            },
            {
                title: 'Tagged Candidates',
                link: endpoints.taggedCandidates,
            },
            {
                title: 'Shortlisted/Saved Candidates',
                link: endpoints.shortlistCandidates,
            },
        ],
    },
    // {
    //     title: 'Search Resume',
    //     titleClassToAddMenu: "profile-more-menu",
    //     onClick: handler => handler.bind(null, 'search'),
    //     link: '',
    //     children: [
    //         {
    //             title: 'Search Resume',
    //             link: '',
    //             onClick: handler => handler.bind(null, 'search'),
    //         },
    //         {
    //             title: 'Calculus',
    //             link: '',
    //             isHidden: !checkFeature('showCalculas'),
    //             onClick: handler => handler.bind(null, 'calculus'),
    //         },
    //     ],
    //     isHidden: !checkFeature('search'),

    // },

    {
        title: 'Search Resume',
        onClick: handler => handler.bind(null, 'search'),
        link: '',
        children:[]
    },

    {
        title: 'Calculus',
        link: '',
        // isHidden: !checkFeature('showCalculas'),
        onClick: handler => handler.bind(null, 'calculus'),
        children:[]
    },
    // {
    //     title: 'Interviews',
    //     link: endpoints.interviews,
    //     isHidden: !checkFeature('interviews') || window.features['interviewsFeature']=="hidden",
    //     // isNew: true,
    //     children: [
    //         {
    //             title: 'Booked Slots',
    //             link: endpoints.interviews,
    //         },
    //         {
    //             title: 'Manage Calendar',
    //             link: endpoints.calendars,
    //             // isNew: true,
    //         },
    //     ],
    // },
    // {
    //     title: 'Assessments',
    //     link: endpoints.assessments,
    //     isHidden:
    //         !checkFeature('assessmentReq') ||
    //         !checkFeature('sendMultiAssessment') ||
    //         !checkFeature('assessment'),
    //     children: [
    //         {
    //             title: 'Manage Assessments',
    //             link: endpoints.assessments,
    //         },
    //         {
    //             title: 'Send Assessment',
    //             link: endpoints.sendAssessment,
    //         },
    //     ],
    // },
    // {
    //     title: 'Assessments',
    //     link: endpoints.assessments,
    //     isHidden:
    //         !checkFeature('assessmentReq') ||
    //         checkFeature('sendMultiAssessment') ||
    //         !checkFeature('assessment'),
    //     children: [],
    // },
    {
        title: 'Courses',
        titleClassToAddMenu: "profile-more-menu",
        link: endpoints.myCourses,
        isReact: true,
        isHidden: !checkFeature('myCourses') || !checkFeature('courses'),
        children: [
            {
                title: 'My Courses',
                link: endpoints.myCourses,
                onClick: handler => handler.bind(null, 'mycourses'),
            },
            {
                title: 'Post Course',
                link: endpoints.postCourse,
                onClick: handler => handler.bind(null, 'postcourse'),
            },
        ],
        showToInstitute: true
    },
    // {
    //     title: 'Reports',
    //     link: endpoints.reports,
    //     children: [],
    //     isHidden: !checkFeature('reports')
    // },
    {
        // in case of changing title, handle case of useEffect in index.js
        title: 'Manager Portal',
        titleClassToAddMenu: "profile-more-menu",
        link: endpoints.managerPortal,
        children: [],
        isHidden: !checkFeature('managerPortal')

    },
    {
        // in case of changing title, handle case of useEffect in index.js
        title: 'Career Assist',
        titleClassToAddMenu: "profile-more-menu",
        link: endpoints.careerAssist,
        //  onClick: handler => handler.bind(null, 'careerAssist'),
        isReact: true,
        children: [],
        isHidden: !checkFeature('careerAssist')
    },
    {
        title: 'More',
        titleClassToAddMenu: "profile-more-menu",
        link: "#",
        children: [
            {
                title: 'Interviews',
                link: endpoints.interviews,
                classToAdd: "interviews",
                hash: "uniqueInterview",
                // FOR NESTED DROPDOWN PURPOSE
                nestedChildren: {
                    title: 'Interviews',
                    link: "#",
                    children: [
                        {
                            title: 'Booked Slots',
                            link: endpoints.interviews,
                            classToAdd: "bookedSlotsLi"
                        },
                        {
                            title: 'Manage Calendar',
                            link: endpoints.calendars,
                            classToAdd: "manageCalendarLi"
                        }
                    ]
                },
            },
            {
                title: 'Assessment',
                link: endpoints.assessments,
                classToAdd: "assessments"
            },
            {
                title: 'Reports',
                link: endpoints.reports,
                classToAdd: "reports"
            },
        ]
    }
];

export default navItems;
